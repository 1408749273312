import React from 'react'
import { Course } from '../components/Course'

import '../styles/Education.css'

//Education
/* import descriptive from '../assets/logos/descriptiva.png'
import probability from '../assets/logos/probabilidad.png'
import inferencial from '../assets/logos/inferencial.png'
import data_science from '../assets/logos/data-science.png'
import data_science_platzi from '../assets/logos/data-science-platzi.png'
import neural_networks from '../assets/logos/redes-neuronales.png'
import mlStanford from '../assets/logos/ML-Stanford.png'
import DLai from '../assets/logos/deep-learning.ai.png'
import tensorflow from '../assets/logos/tensorflow-certificate.jpg' */


const courses = [
    {
        id: 1,
        image_url: "https://imgur.com/NSVq7oP.png",
        title: 'Descriptive Statistic',
        description: [
            'Qualitative, quantitative and ordinal data types and the correct analysis of each of them.',
            'Statistic values. Mean, Mode, Median, Kurtosis and Skew.',
            'Complete descriptive analysis.',
            'Using graphs to represent statistical data.',
        ],
        by: 'Juan Gabriel Gomila Salas',
        who_is: 'Mathematician, specialized in data analysis for video game companies with R and Python.',
        url_course: 'https://cursos.frogamesformacion.com/courses/estadistica-descriptiva',
        url_certificate: 'https://www.udemy.com/certificate/UC-19362fb6-1a76-4c91-adb4-e4448ab66daf/'
    },
    {
        id: 2,
        image_url: "https://imgur.com/mfA3ROv.png",
        title: 'Probability for Machine Learning',
        description: [
            'Solve probability problems using R and Python.',
            'Concepts of correlation and independence of variables.',
            'Probability functions for discrete and continuous variables.',
            'Central Limit Theorem.'
        ],
        by: 'Juan Gabriel Gomila Salas',
        who_is: 'Mathematician, specialized in data analysis for video game companies with R and Python.',
        url_course: 'https://cursos.frogamesformacion.com/courses/probabilidad',
        url_certificate: 'https://www.udemy.com/certificate/UC-460fec56-6e21-45a5-8bc9-b9dd0fe9286b/'
    },
    {
        id: 3,
        image_url: "https://imgur.com/BS1fTEW.png",
        title: 'Inferencial Statistic',
        description: [
            'Solve problems about inferencia statistic.',
            'Estimate parameters of a population from a sample.',
            'Calculate and interpret a parametric and non-parametric hypothesis test.',
            'Fit using simple and multiple linear regression models.'
        ],
        by: 'Juan Gabriel Gomila Salas',
        who_is: 'Mathematician, specialized in data analysis for video game companies with R and Python.',
        url_course: 'https://cursos.frogamesformacion.com/courses/estadistica-inferencial',
        url_certificate: '/'
    },
    {
        id: 4,
        image_url: "https://imgur.com/oHNSHnb.png",
        title: 'Data Science with Python',
        description: [
            'Operations with data. Cleaning and preparation.',
            'Supervised and unsupervised learning.',
            'Build diverse Machine Learning models to solve problems. Understanding the results and making conclusions.',
            'Introduction to Neural Networks.'
        ],
        by: 'Juan Gabriel Gomila Salas',
        who_is: 'Mathematician, specialized in data analysis for video game companies with R and Python.',
        url_course: 'https://cursos.frogamesformacion.com/courses/machine-learning-python',
        url_certificate: '/'
    },
    {
        id: 5,
        image_url: "https://imgur.com/znuuOg2.png",
        title: 'Data Science School at Platzi',
        description: [
            'Data visualization using Pandas, Matplotlib, seaborn.',
            'BI tools. Tableau, Power BI and Google Data Studio.',
            'Data Engineer tools, ETL, Web Scraping.',
            'Machine Learning models and Neural Networks.'
        ],
        by: 'Platzy',
        who_is: 'Effective online professional education. Teachers are professionals who are currently applying the techniques they teach.',
        url_course: 'https://platzi.com/datos/',
        url_certificate: '/'
    },
    {
        id: 6,
        image_url: "https://imgur.com/MFoEQ6S.png",
        title: 'Artificial Intelligence',
        description: [
            'Understand the concepts of modern artificial intelligence as well as Deep Learning and Q-Learning.',
            'Understand the most advanced aspects of artificial intelligence.',
            'ANN, CNN, RNN and others.',
            'The theory behind Deep Q Learning.',
            'Build neural networks.'
        ],
        by: 'Juan Gabriel Gomila Salas',
        who_is: 'Mathematician, specialized in data analysis for video game companies with R and Python.',
        url_course: 'https://cursos.frogamesformacion.com/courses/deep-learning-az',
        url_certificate: '/'
    },
    {
        id: 7,
        image_url: "https://imgur.com/PiHM9uC.png",
        title: 'Machine Learning by Stanford',
        description: [
            'Introduction to machine learning, datamining, and statistical pattern recognition.',
            'Supervised and Unsupervised learning.',
            'Linear regression, logistic regression, SVM, Neural Networks.',
            'Best practice in Machine Learning.',
            'Clustering, dimensionality reduction, recommender systems.',
            'Numerous case studies and applications. Such as anomalies detection, text understanding, anti-spam and others.'
        ],
        by: 'Andrew NG',
        who_is: 'IA Research, Founder of DeepLearning.AI, Co-founder of Courcera',
        url_course: 'https://www.coursera.org/learn/machine-learning?utm_source=gg&utm_medium=sem&utm_campaign=07-StanfordML-US&utm_content=B2C&campaignid=685340575&adgroupid=35524166541&device=c&keyword=stanford%20machine%20learning%20certificate&matchtype=b&network=g&devicemodel=&adpostion=&creativeid=243289762922&hide_mobile_promo&gclid=CjwKCAiAprGRBhBgEiwANJEY7B0CHQXCqJ6LFCwG5zf7lNcoWLfloNjnQXmMerhdCA-uNpI3yNf-GBoCgNAQAvD_BwE',
        url_certificate: '/'
    },
    {
        id: 8,
        image_url: "https://imgur.com/Wlf1nR2.png",
        title: 'Deep Learning Specialization',
        description: [
            'Build and train deep neural networks, identify key architecture parameters, implement vectorized neural networks and deep learning to applications.',
            'Build a CNN and apply it to detection and recognition tasks.',
            'Train test sets, analyze variance for DL applications.',
            'Build and train RNNs, work with NLP and Word Embeddings, and use HuggingFace tokenizers and transformer models to perform NER and Question Answering',
        ],
        by: 'Andrew NG',
        who_is: 'IA Research, Founder of DeepLearning.AI, Co-founder of Courcera',
        url_course: 'https://www.coursera.org/specializations/deep-learning',
        url_certificate: '/'
    },
    {
        id: 9,
        image_url: "https://imgur.com/FA8ZYXD.jpg",
        title: 'Tensorflow Mastery Cource',
        description: [
            'Learn to build all types of Machine Learning Models using the latest TensorFlow 2',
            'Build image recognition, object detection, text recognition algorithms with deep neural networks and convolutional neural networks',
            'Applying Deep Learning for Time Series Forecasting',
            'Build TensorFlow models using Computer Vision, Convolutional Neural Networks and Natural Language Processing',
        ],
        by: 'Daniel Bourke',
        who_is: 'Self-taught Machine Learning Engineer, ML Engineer on Australia Artificial intelligence Agency Max Kelsen' ,
        url_course: 'https://www.udemy.com/course/tensorflow-developer-certificate-machine-learning-zero-to-mastery/',
        url_certificate: '/'
    },
]

const Education = () => {
    return(
        <div className="education--container">
            <div className="content--container">
                <h1>Courses that I have taken</h1>
                <div className="all-courses">
                    {courses.map(course => 
                            <div className="courses--container"
                            key = {course.id}>
                                <Course
                                    image_url = {course.image_url}
                                    title = {course.title}
                                    description = {course.description}
                                    by = {course.by}
                                    who_is = {course.who_is}
                                    url_course = {course.url_course}
                                    url_certificate= {course.url_certificate}
                                />
                            </div>
                        )}
                </div>
            </div>
        </div>
    )
}

export { Education }


