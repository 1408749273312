import React from 'react'

import '../styles/Description.css'


const Description = () => {
    return (
        <div className="descrip-container">
            <div className="description-container">
                <h3>
                    Industrial Engineer as a profesional. Self-Learning Data Scientits & Machine Learning Engineer. 
                </h3>
                <h3>
                    My love for the maths and my curiosity to learn new things are the main reasons that I enjoy to work with Data. 
                </h3>
                <h3>
                    I am ready to create something awesome.
                </h3>
                <a href="https://drive.google.com/file/d/1zO7eFEpTiMJNv_KFNGHaNho3OcyJmLIn/view?usp=sharing" target="_blank">
                    <button type='text'>
                        Download my resume
                    </button>
                </a>
            </div>
        </div>
    )
}

export { Description }
