import React from 'react'

import { Skill } from '../components/Skill'

import '../styles/Skills.css'

import teamwork from '../images/icons/Teamwork.svg'
import communication from '../images/icons/Communication.svg'
import leadership from '../images/icons/Leadership.svg'
import commitment from '../images/icons/Commitment.svg'
import curiosity from '../images/icons/Curiosity.svg'
import responsibility from '../images/icons/Responsibility.svg'

const skills = [
    {
        id: 1,
        groupName: 'Programming Languages',
        skills: [
            {
                id: 1,
                name: 'Python/Notebooks',
                progress: 90
            },
            {
                id: 2,
                name: 'R & R-Studio',
                progress: 55
            },
            {
                id: 3,
                name: 'JavaScript',
                progress: 70
            },
            {
                id: 4,
                name: 'SQL',
                progress: 75
            }
        ]
    },
    {
        id: 2,
        groupName: 'Data Operation',
        skills: [
            {
                id: 1,
                name: 'Cleaning',
                progress: 95
            },
            {
                id: 2,
                name: 'Operations',
                progress: 90
            },
            {
                id: 3,
                name: 'Transformation',
                progress: 85
            },
            {
                id: 4,
                name: 'Visualization',
                progress: 90
            }
        ]
    },
    {
        id: 3,
        groupName: 'Maths',
        skills: [
            {
                id: 1,
                name: 'Statistics',
                progress: 85
            },
            {
                id: 2,
                name: 'Probability',
                progress: 80
            },
            {
                id: 3,
                name: 'Lineal Algebra',
                progress: 90
            },
            {
                id: 4,
                name: 'Calculus',
                progress: 70
            }
        ]
    },
    {
        id: 4,
        groupName: 'ML Algorithms',
        skills: [
            {
                id: 1,
                name: 'Regressions',
                progress: 95
            },
            {
                id: 2,
                name: 'Clasifications',
                progress: 90
            },
            {
                id: 3,
                name: 'Clusters',
                progress: 85
            },
            {
                id: 4,
                name: 'Dim. Reduction',
                progress: 90
            }
        ]
    },
    {
        id: 5,
        groupName: 'DL Algorithms',
        skills: [
            {
                id: 1,
                name: 'Regression NN',
                progress: 95
            },
            {
                id: 2,
                name: 'Classification NN',
                progress: 95
            },
            {
                id: 3,
                name: 'CNN',
                progress: 90
            },
            {
                id: 4,
                name: 'RNN',
                progress: 80
            }
        ]
    },
    {
        id: 6,
        groupName: 'ML & DL Frameworks',
        skills: [
            {
                id: 1,
                name: 'Numpy',
                progress: 90
            },
            {
                id: 2,
                name: 'Pandas',
                progress: 90
            },
            {
                id: 3,
                name: 'Matplotlib',
                progress: 90
            },
            {
                id: 4,
                name: 'Scikit Learn',
                progress: 85
            },
            {
                id: 5,
                name: 'Tensorflow',
                progress: 90
            }
        ]
    },
    {
        id: 7,
        groupName: 'BI Skills',
        skills: [
            {
                id: 1,
                name: 'Tableau',
                progress: 75
            },
            {
                id: 2,
                name: 'Power BI',
                progress: 75
            },
            {
                id: 3,
                name: 'Excel',
                progress: 80
            }
        ]
    },
    {
        id: 8,
        groupName: 'Other Skills',
        skills: [
            {
                id: 1,
                name: 'Git-GitHub',
                progress: 90
            },
        ]
    }
]

const softskills = [
    {
        id: 1,
        name: 'Teamwork',
        image_url: teamwork
    },
    {
        id: 2,
        name: 'Communication',
        image_url: communication
    },
    {
        id: 3,
        name: 'Leadership',
        image_url: leadership
    },
    {
        id: 4,
        name: 'Commitment',
        image_url: commitment
    },
    {
        id: 5,
        name: 'Curiosity',
        image_url: curiosity
    },
    {
        id: 6,
        name: 'Responsibility',
        image_url: responsibility
    }
]

const Skills = () => {
    return(
        <div className="skills--container">
            <div className="skills-content--container">
                <h1>Hard Skills</h1>
                <div className="all-skills--container">
                    {skills.map(group => (
                        <div 
                        key={group.id}
                        className="skills-group--container">
                            <h3>{group.groupName}</h3>
                            {group.skills.map(skill => (
                                <Skill
                                    key={skill.id} 
                                    name = {skill.name}
                                    progress = {skill.progress}
                                />
                            ))}
                        </div>
                    ))}
                </div>
                <h1>Soft Skills</h1>
                <div className="all-skills--container">
                    <div className="softskills--container">
                        {softskills.map(skill => (
                            <div 
                                key={skill.id}
                                className="softskill--container">
                                <div className="softskill-img--container">
                                    <img src={skill.image_url} alt="" />
                                </div>
                                <p className="softskill-name">{skill.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export { Skills }