import React from 'react'

import '../styles/Course.css'

const Course = ({
    title,
    image_url,
    description,
    by,
    who_is,
    url_course,
    url_certificate,
}) => {
    return(
        <div className="course--container">
            <div className="image--container">
                <img src={image_url} alt="image_course" />
            </div>
            <h3>{title}</h3>
            <ul>
                {description.map(desc => (
                    <li>{desc}</li>
                ))}
            </ul>
            <h3>By:</h3>
            <p><strong>{by}</strong></p>
            <p>{who_is}</p>
            <div className="buttons--container">
                <a href={url_course} target="_blank" rel="noopener noreferrer">
                    <button className="buttons-certificate">
                        Know more
                    </button>
                </a>
                {/* <a href={url_course}>
                    <button 
                    className="buttons-know">
                        Know more
                    </button>
                </a> */}
            </div>
        </div>
    )
}

export { Course }