import React from 'react'
import { Hobby } from '../components/Hobby'

import '../styles/Hobbies.css'

/* import always_learning from '../assets/logos/always-learning.png'
import photografy from '../assets/logos/fotografia.png'
import weather from '../assets/logos/clima.png'
import web_development from '../assets/logos/web-development.png' */

const hobbies = [
    {
        id: 1,
        title: 'Always Learning',
        image_url: "https://imgur.com/qCBZIyz.png",
        description: 'I am on the side that we have to be updated  all the time. In the Tech industry, the tools to create the magic are always in improvement, ve have to be as same time.'
    },
    {
        id: 2,
        title: "Photography",
        image_url: 'https://imgur.com/xlqzufx.png',
        description: 'Photography helps me forget the routine, gives peace, tranquility, helps me meditate and order my emotional life.'
    },
    {
        id: 3,
        title: 'Weather',
        image_url: "https://imgur.com/K6AX6TB.png",
        description: 'Seeing those dark skies, seeing those gray clouds about to fall, is one of the best landscapes my eyes can see. Rainy days are the best.'
    },
    {
        id: 4,
        title: 'Web Development',
        image_url: "https://imgur.com/fk7T4rI.png",
        description: 'If I did not love data science, my first choice would be web development. So much so that I consider it as a second profession. It is analytical, it is creative and what we can create is out of the imagination.'
    }
]

const Hobbies = () => {
    return(
        <div className="hobbies--container">
            <div className="content-hobbies--container">
                <h1>My Hobbies</h1>
                <div className="all-hobbies">
                    {hobbies.map(hobby => (
                        <div className="my-hobby--container" key={hobby.id}>
                            <Hobby
                            title = {hobby.title}
                            image_url = {hobby.image_url}
                            description = {hobby.description}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export { Hobbies }