import React from 'react'

import { Intro } from '../components/Intro'
import { Description } from '../components/Description'

import '../styles/Hero.css'

/* import my_photo from '@logos/photo.png' */


const Hero = () => {
    return (
        <div className="hero-container">
            <div className="intro-desc--container">
                <Intro />
                <Description />
            </div>
            <div className='image-container'>
                <img src="https://imgur.com/MvXUsNZ.png" alt="MyPhoto" />
            </div>
        </div>
    )
}

export { Hero }