import React from 'react'

import '../styles/Intro.css'

const Intro = () => {
    return (
        <div className="intro-container">
            <div className="intro-desc">
                <p>Welcome!. My name is <span><strong>José Higuera</strong></span> and I'm a <span><strong>Data Scientits</strong></span> and <span><strong>Machine Learning</strong></span> engineer.</p>
            </div>
        </div>
    )
}

export { Intro }