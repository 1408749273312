import React from 'react'

import '../styles/Hobby.css'

const Hobby = ({
    title,
    image_url,
    description
}) => {
    return(
        <div className="hobby-container">
            <div className="image-hobby--container">
                <img src={image_url} alt="HobbyImage" />
            </div>
            <h3>{title}</h3>
            <p>{description}</p>
        </div>
    )
}

export { Hobby }