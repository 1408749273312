import React from 'react'

import '../styles/Skill.css'

const Skill = ({ name, progress }) => {
    return(
        <div className="skill--container">
            <p>{name}</p>
            <div className={`skill__bar skill__bar--${progress}`}></div>
        </div>
    )
}

export { Skill }